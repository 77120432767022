import "./styles/custom_bootstrap.scss";
import "./styles/screen.scss";
import "./polyfills";
import "./utils/fixHoverTouch";
import noticeError from "./utils/noticeError";
import $ from "jquery";
import queryString from "query-string";
import { addSvgDefinitionsToBody } from "./utils/svgIcons";

// import everything from rxjs to make sure epics work as intended
import "rxjs";
import makeAjaxPromise from "./utils/makeAjaxPromise";
import { LoggedInUser } from "./types/loggedInUser";
import React from "react";
import { createRoot, Root } from "react-dom/client";
import { LoadingMessage } from "./components/ui/LoadingMessage";
import { InitializeAppDataFromServer } from "./types/InitializeAppDataFromServer";
import { LandingPage } from "./components/landingPage/LandingPage";
import NativeLoginForm from "./components/nativeApp/NativeLoginForm";
import { addAssetsPathsForFrontMatterPages } from "./addAssetsPathsForFrontMatterPages";
import "configureOpenAPI";

const { FastClick } = require("lib/fastclick");
window.addEventListener("load", function () {
	(FastClick as any).attach(document.body);
});

window.addEventListener("unhandledrejection", function (event: any) {
	event.preventDefault();
	noticeError(event.reason);
});

const documentReadyPromise = new Promise((resolve) => {
	$(resolve);
});

let createRootPromise: Promise<Root> | undefined;
const getReactRoot = (): Promise<Root> => {
	if (!createRootPromise) {
		createRootPromise = documentReadyPromise.then(() => {
			const rootEl = document.getElementById("root")!;
			const root = createRoot(rootEl);
			return root;
		});
	}
	return createRootPromise;
};

export async function startMksap() {
	try {
		validateBrowserSupport();

		const showLoadedMessageAndReturnRoot = getReactRoot().then((root) => {
			root.render(<LoadingMessage></LoadingMessage>);
			return root;
		});

		reallyStartMksap({
			loggedInUserPromise: makeAjaxPromise<LoggedInUser>({
				url: "/api/user.json",
			}),
			dataFromServerPromise: makeAjaxPromise<InitializeAppDataFromServer>({
				url: "/api/user/details.json",
			}),
			reactRootPromise: showLoadedMessageAndReturnRoot,
		});
	} catch (error) {
		document.body.innerHTML = `
			<div class="alert alert-danger">There was an error loading MKSAP. Please refresh the page shortly to try again.</div>
		`;
		throw error;
	}
}

export async function startMksapNative(userInfo) {
	reallyStartMksap({
		loggedInUserPromise: Promise.resolve(userInfo),
		dataFromServerPromise: Promise.resolve({}),
		reactRootPromise: getReactRoot(),
	});
}

async function reallyStartMksap({
	loggedInUserPromise,
	dataFromServerPromise,
	reactRootPromise,
}: {
	loggedInUserPromise: Promise<LoggedInUser>;
	dataFromServerPromise: Promise<InitializeAppDataFromServer>;
	reactRootPromise: Promise<Root>;
}) {
	await Promise.all([]);

	const [{ MksapApplication }, userInfo, dataFromServer, root] =
		await Promise.all([
			import(/* webpackChunkName: "MksapApplication" */ "./MksapApplication"),
			loggedInUserPromise,
			dataFromServerPromise,
			reactRootPromise,
		]);
	const mksapApplication = new MksapApplication();
	(window as any).mksapApplication = mksapApplication;
	mksapApplication.start(root, userInfo, dataFromServer);
}

function validateBrowserSupport(): void {
	if (!isBrowserSupported()) {
		$(() => {
			$(document.body).html(`
			<div style="margin:auto;padding:30px;text-align:center;width:700px;">
<p><strong>Please upgrade your browser to use ACP MKSAP</strong></p>
<p>We built ACP MKSAP using the latest techniques and technologies. This makes ACP MKSAP faster and easier to use. Unfortunately, your browser doesn't support those technologies.</p>
<p>Visit <a href="https://browsehappy.com/">https://browsehappy.com/</a>  to download or upgrade to the latest browsers.</p>
</div>
`);
		});
		throw new Error("browser isn't supported");
	}
}

function ensureEulaIsAccepted() {
	$(() => {
		const $form = $("#license-accept");
		const $checkbox = $("#agreement-check");
		const $button = $form.find("button");

		$checkbox.on("change", function () {
			const checked: any = !$checkbox.is(":checked");
			$button.attr("disabled", checked);
		});

		$form.on("submit", function (event) {
			if (!$checkbox.is(":checked")) {
				event.preventDefault();
			}
		});
	});
}

async function startLandingPage({
	showComingSoonMessage = false,
}: {
	showComingSoonMessage?: boolean;
} = {}) {
	const root = await getReactRoot();

	const onLogin = (_loggedInUser: LoggedInUser) => {
		const forwardPath = getForwardPath();

		window.location.pathname = forwardPath;
	};
	root.render(
		<LandingPage
			onLogIn={onLogin}
			showComingSoonMessage={showComingSoonMessage}
		/>,
	);

	function getForwardPath(): string {
		// When a user attempts to access a restricted page before logging in, the server redirects
		// to the login form, passing the path to the page they were trying to access in the `forward`
		// param in the query string.
		// This function returns that value, or "/app" if the param is not set.
		const queryParams = queryString.parse(window.location.search);
		if (typeof queryParams.forward === "string") {
			return queryParams.forward;
		} else {
			return "/app";
		}
	}
}

function isBrowserSupported(): boolean {
	if (window.CSS && CSS.supports("color", "var(--primary)")) {
		return true;
	} else {
		return false;
	}
}

async function startNativeAppLoginFormTest() {
	const root = await getReactRoot();

	const onLogin = (_loggedInUser: LoggedInUser) => {
		alert("login successful! the app would start now");
	};
	root.render(<NativeLoginForm onLogIn={onLogin} />);
}

$(() => {
	addAssetsPathsForFrontMatterPages();
});

export const MksapEntryPoints = {
	startMksap,
	startLandingPage,
	ensureEulaIsAccepted,
	startNativeAppLoginFormTest,
	addSvgDefinitionsToBody,
	addAssetsPathsForFrontMatterPages,
};
(window as any).MksapEntryPoints = MksapEntryPoints;
