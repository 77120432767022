import imagePath from "./utils/imagePath";

export function addAssetsPathsForFrontMatterPages() {
	for (const el of document.querySelectorAll("[data-image-src]")) {
		const imagePathAttr = el.getAttribute("data-image-src");
		if (!imagePathAttr) {
			continue;
		}
		const fullImagePath = imagePath(imagePathAttr);
		console.log(el, imagePath, fullImagePath);
		el.setAttribute("src", fullImagePath);
	}
}
