import { AjaxPromiseOptions } from "types/ajax";
import { getAjaxRequestOptions } from "./getAjaxRequestOptions";
import { Subject } from "rxjs";

/**
 * Custom error containing the rejected response.
 * @param {Object} response
 * @return {Object} http error
 */
export class HttpError extends Error {
	readonly response: Response;
	readonly friendlyErrorMessage?: string;

	constructor(response: Response, friendlyErrorMessage?: string) {
		super(
			`HTTP Error ${response.status}: ${
				friendlyErrorMessage || response.statusText
			}`,
		);
		this.response = response;
		this.friendlyErrorMessage = friendlyErrorMessage;
	}
}

type AjaxResponseNotification =
	| { type: "NETWORK_ERROR" }
	| { type: "SERVER_RESPONSE"; response: Response };

/**
 * An RXJS subject that notifies observers whenever an ajax request is completed. This is used to update the network status
 * and to watch for 401 responses from the server.
 */
export const ajaxResponseSubject: Subject<AjaxResponseNotification> =
	new Subject<AjaxResponseNotification>();

/**
 * Return a promise wrapping a fetch call.
 * NOTE: Fetch requests currently don't support abort yet.
 * @param {Object} options
 * @return {Object} promise
 */
export const makeAjaxPromise = <T>(options: AjaxPromiseOptions): Promise<T> => {
	const { url } = options;
	const requestOptions = getAjaxRequestOptions(options);
	const dataType = options.dataType || "application/json";

	const request = fetch(url, requestOptions).then(
		// Not unit tested
		(response) => {
			ajaxResponseSubject.next({
				type: "SERVER_RESPONSE",
				response,
			});
			// NetworkStatusWatcher.networkRequestSucceeded();
			if (response.ok) {
				const contentType = response.headers.get("content-type");
				if (
					(contentType && /^application\/json;?/.exec(contentType)) ||
					dataType === "application/json" ||
					dataType === "text/json"
				) {
					return response.json();
				}
				return response.text();
			}

			// This was an error response. Try parsing the response to see if it contains a friendly error message
			return response
				.clone()
				.json()
				.then((bodyJson) => {
					const errorMessage = bodyJson.errorMessage || bodyJson.error;
					throw new HttpError(response, errorMessage);
				})
				.catch((error: any) => {
					// Error getting the json body. Just throw an HttpError without a friendly error message
					throw new HttpError(response, error.friendlyErrorMessage);
				});
		},
		// Not unit tested
		(error) => {
			// Handle errors returned by fetch(). Note that this does not include non-200 responses, since those result
			// in successful promises that are handled above.
			ajaxResponseSubject.next({
				type: "NETWORK_ERROR",
			});
			// NetworkStatusWatcher.networkRequestFailed();
			throw error;
		},
	);

	return request;
};

export default makeAjaxPromise;
