import LoginBackend from "backends/LoginBackend";
import React, { useState, useLayoutEffect, useCallback } from "react";
import { Button } from "../ui/Button";
import {
	Alert,
	Card,
	Container,
	FormControl,
	FormLabel,
} from "../ui/MksapReactBootstrap";
import { addSvgDefinitionsToBody } from "../../utils/svgIcons";
import { LoggedInUser } from "@mksap/types/loggedInUser";
import { useUniqueId } from "../hooks/useUniqueId";
import imagePath from "@mksap/utils/imagePath";

interface NativeLoginFormProps {
	onLogIn(userData: LoggedInUser);
}

export const NativeLoginForm: React.FC<NativeLoginFormProps> = ({
	onLogIn,
}) => {
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [errorText, setErrorText] = useState<string | undefined>(undefined);
	const [username, setUsername] = useState<string>("");
	const [password, setPassword] = useState<string>("");

	const handleSubmit = useCallback(
		async (event: React.FormEvent) => {
			event.preventDefault();
			if (submitting === true) {
				return;
			}
			setSubmitting(true);
			try {
				const userData = await LoginBackend.logIn(username, password);
				onLogIn(userData);
			} catch (err) {
				if (err.friendlyErrorMessage) {
					setErrorText(err.friendlyErrorMessage);
				} else {
					setErrorText(err.message);
				}

				setSubmitting(false);
			}
		},
		[onLogIn, submitting, setSubmitting, username, password, setErrorText],
	);

	useLayoutEffect(() => addSvgDefinitionsToBody(), []);

	const handleUnChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) =>
			setUsername(event.currentTarget.value),
		[setUsername],
	);

	const handlePwChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) =>
			setPassword(event.currentTarget.value),
		[setPassword],
	);

	const usernameId = useUniqueId("username");
	const passwordId = useUniqueId("password");

	return (
		<div className="bg-primary bg-gradient center vh-100 native-app-login-form">
			<div className="web-login-form top-0 start-0 bottom-0 end-0">
				<Container fluid="xl" className="top-50 start-50">
					<div className="row justify-content-center m-md-5">
						<div className="col-md-7 col--form mt-4 mt-md-0">
							<Card className="h-100 d-flex flex-column pt-4 pt-md-0">
								<img
									src={imagePath("acp-mksap-logo-horiz-4c-tagline.svg")}
									className="card-img-top pt-4 px-3"
									alt="ACP MKSAP"
								/>
								<Card.Body>
									<form className="h-100" onSubmit={handleSubmit}>
										<div className="d-flex flex-column h-100">
											<div>
												<div className="mb-3">
													<FormLabel htmlFor={usernameId}>Username</FormLabel>
													<FormControl
														autoComplete="username"
														className=""
														value={username}
														name="username"
														id={usernameId}
														onChange={handleUnChange}
													/>
												</div>
												<div className="mb-3">
													<FormLabel htmlFor={passwordId}>Password</FormLabel>

													<FormControl
														className=""
														name="password"
														id={passwordId}
														autoComplete="current-password"
														type="password"
														value={password}
														onChange={handlePwChange}
													/>
												</div>
												{errorText && (
													<Alert className="mb-3" variant="danger">
														{errorText}
													</Alert>
												)}
												<div className="d-grid gap-2 py-3 col-10 mx-auto">
													<Button type="submit">Login</Button>
												</div>
											</div>
										</div>
									</form>
								</Card.Body>
							</Card>
						</div>
					</div>
				</Container>
			</div>
		</div>
	);
};

export default NativeLoginForm;
