import React from "react";
import "./ButtonSpinner.scss";

interface ButtonSpinnerProps {}

export const ButtonSpinner: React.FC<ButtonSpinnerProps> = () => {
	return (
		<svg
			className="button-spinner svg-icon svg-icon__first"
			viewBox="25 25 50 50"
		>
			<circle
				className="button-spinner__path"
				cx="50"
				cy="50"
				r="20"
				fill="none"
				strokeWidth="2"
				strokeMiterlimit="10"
			/>
		</svg>
	);
};
