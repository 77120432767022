import { isString } from "lodash-es";
declare const NREUM;

export default function noticeError(error: any) {
	if (isString(error)) {
		error = new Error(error);
	}
	// tslint:disable-next-line: no-console
	console.error(error);
	if (typeof NREUM !== "undefined") {
		NREUM.noticeError(error);
	}
}
