// Not unit tested
import $ from "jquery";

type DomObj = HTMLElement | Element | Node | NodeList;

export const offset = (el: DomObj) => {
	return $(el).offset();
};

export const position = (el: DomObj) => {
	return $(el).position();
};

export const closest = (el: DomObj, selectorOrEl: any): any => {
	return $(el).closest(selectorOrEl)[0];
};

export const width = (el: DomObj) => {
	return $(el).width();
};

export const height = (el: DomObj) => {
	return $(el).height();
};

export const css = (el: DomObj, ruleNameOrOptions: any) => {
	const cssRuleValue = $(el).css(ruleNameOrOptions);
	if (typeof ruleNameOrOptions === "string") {
		return cssRuleValue;
	}
};

export const hasClass = (el: any, className: string) => {
	if (el.classList) {
		return el.classList.contains(className);
	} else {
		return new RegExp("(^| )" + className + "( |$)", "gi").test(el.className);
	}
};

export const addClass = (el: DomObj, className: string) => {
	$(el).addClass(className);
};

export const removeClass = (el: DomObj, className: string) => {
	$(el).removeClass(className);
};

export const on = (
	el: DomObj,
	events: string,
	handler: (event: any, ...args: any[]) => any,
) => {
	$(el).on(events, handler);
};

export const off = (
	el: DomObj,
	events: string,
	handler?: (event: any, ...args: any[]) => any,
) => {
	$(el).off(events, null as any, handler as any);
};

// export const prevAll = (el: DomObj, selector?: string): any[] => {
// 	const prevAllNodes: any[] = [];
// 	const $prevAll = $(el).prevAll(selector);
// 	$prevAll.each((_index, prevAllNode) => {
// 		prevAllNodes.push(prevAllNode);
// 	});
// 	return prevAllNodes;
// };

export const isVisible = (el: DomObj): boolean => {
	return $(el).is(":visible");
};

export const outerHeight = (el: DomObj): any => {
	return $(el).outerHeight();
};

export const scrollTop = (el: DomObj): any => {
	return $(el).scrollTop();
};

export const onDocumentReady = (callback: any): any => {
	$(callback);
};

export const html = (el: DomObj, htmlEl: DomObj): any => {
	$(el).html($(htmlEl).html());
};
