import * as React from "react";
import { IconName } from "types/iconName";
import classNames from "classnames";
import { getSvgIconHref } from "../../utils/svgIcons";
import { ClickableTooltip } from "./ClickableTooltip";
import { uniqueId } from "lodash-es";
import "./Icon.scss";

export interface IconProps extends React.SVGAttributes<unknown> {
	name: IconName;
	className?: string;
	role?: string;
	title?: string;
}

const Icon: React.FC<IconProps> = ({
	name,
	className,
	children,
	"aria-hidden": ariaHidden,
	"aria-label": ariaLabel,
	"aria-labelledby": ariaLabelledBy,
	...otherProps
}) => {
	const fullClassName = classNames("svg-icon", `svg-${name}`, className);

	// Default aria-hidden to true if there is no label.
	if (
		typeof ariaHidden === "undefined" &&
		typeof ariaLabel === "undefined" &&
		typeof ariaLabelledBy === "undefined"
	) {
		ariaHidden = true;
	}

	return (
		<svg
			className={fullClassName}
			{...otherProps}
			aria-hidden={ariaHidden}
			aria-label={ariaLabel}
			aria-labelledby={ariaLabelledBy}
		>
			<use xlinkHref={getSvgIconHref(name)} />
		</svg>
	);
};

interface IconImageProps {
	name: IconName;
	title: string;
	className?: string;
	onClick?: any;
	style?: any;
	tabIndex?: number;
}

export const IconImage: React.FC<IconImageProps> = ({
	name,
	title,
	className,
	...otherProps
}) => {
	const fullClassName = classNames("svg-icon", `svg-${name}`, className);
	const titleId = uniqueId("iconImage-");
	return (
		<svg
			aria-labelledby={titleId}
			role="img"
			className={fullClassName}
			{...otherProps}
		>
			<title id={titleId}>{title}</title>
			<use xlinkHref={getSvgIconHref(name)} />
		</svg>
	);
};

const HospitalistIcon: React.FC<{}> = () => {
	return (
		<ClickableTooltip content={<>Contains hospitalist content</>}>
			<Icon name="hospitalist" />
		</ClickableTooltip>
	);
};

const HvcIcon: React.FC<{}> = () => {
	return (
		<ClickableTooltip content={<>High Value Care Recommendation</>}>
			<Icon name="hvc" className="me-2" />
		</ClickableTooltip>
	);
};

export { Icon, HospitalistIcon, HvcIcon };
export default Icon;
