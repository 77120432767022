import React from "react";
import { LoggedInUser } from "@mksap/types/loggedInUser";
import { WebLoginForm } from "./WebLoginForm";
import { Card, Container } from "../ui/MksapReactBootstrap";
import imagePath from "@mksap/utils/imagePath";
import { LandingPageCarousel } from "./LandingPageCarousel";
import { Button } from "../ui/Button";

interface LandingPageProps {
	onLogIn(userData: LoggedInUser): void;
	showComingSoonMessage: boolean;
}

export const LandingPage: React.FC<LandingPageProps> = ({
	onLogIn,
	showComingSoonMessage,
}) => {
	const slides = [
		{
			image: imagePath("placeholder-screenshot-syllabus.png"),
			text: (
				<div className="ms-2">
					<h5>Created by physicians, for physicians</h5>
					<p>
						A comprehensive resource for clinical knowledge needed in Internal
						Medicine.
					</p>
				</div>
			),
		},
		{
			image: imagePath("placeholder-screenshot-questionbank.png"),
			text: (
				<div className="ms-2">
					<h5>A Robust Question Bank</h5>
					<p>
						Case-based clinical questions with single-best-answer options and
						detailed critiques.
					</p>
				</div>
			),
		},
		{
			image: imagePath("placeholder-screenshot-learningplan.png"),
			text: (
				<div className="ms-2">
					<h5>Personalized Learning Plan</h5>
					<p>
						Target topics to deepen your understanding with related content and
						learning links.
					</p>
				</div>
			),
		},
	];

	return (
		<div className="bg-primary bg-gradient center">
			<div className="web-login-form top-0 start-0 bottom-0 end-0">
				<Container fluid="xl" className="top-50 start-50">
					<div className="row justify-content-center m-lg-5">
						<div className="col-md-5 col--form px-sm-4 py-4">
							{showComingSoonMessage ? (
								<div className="coming-soon">
									<div className="my-4">
										<img
											src={imagePath("acp-logo-horiz-w.svg")}
											className=""
											alt="ACP MKSAP"
										/>
									</div>
									<h1 className="h2 text-white">
										ACP MKSAP launches{" "}
										<span className="text-nowrap">February 3, 2025</span>
									</h1>
									<div className="mt-3">
										<Button
											variant="outline-light"
											className=""
											href="https://www.acponline.org/featured-products/acp-mksap"
										>
											Learn More
										</Button>
									</div>
								</div>
							) : (
								<Card className="login-card h-100 d-flex flex-column pt-4 pt-md-0">
									<img
										src={imagePath("acp-mksap-logo-horiz-4c-tagline.svg")}
										className="card-img-top pt-4 px-3"
										alt="ACP MKSAP"
									/>
									<Card.Body>
										<WebLoginForm onLogIn={onLogIn} />
									</Card.Body>
								</Card>
							)}
						</div>
						<div className="col col--slider h-100 px-sm-4 py-4">
							<LandingPageCarousel slides={slides} />
						</div>
					</div>
				</Container>
			</div>
		</div>
	);
};
