import * as $ from "utils/jQueryShim";

// Not unit tested
$.onDocumentReady(() => {
	const bodyEl = document.querySelector("body")!;
	let touchEndTimeout: number | null = null;

	function enableHover() {
		$.addClass(bodyEl, "app-allow-hover");
	}

	function disableHover() {
		$.removeClass(bodyEl, "app-allow-hover");
	}

	function onTouchStart() {
		if (touchEndTimeout) {
			window.clearTimeout(touchEndTimeout);
		}
		$.off(bodyEl, "mousemove", enableHover);
		disableHover();
	}

	function onTouchEnd() {
		touchEndTimeout = window.setTimeout(function () {
			$.on(bodyEl, "mousemove", enableHover);
		}, 100);
	}

	enableHover();
	$.on(bodyEl, "touchstart", onTouchStart);
	$.on(bodyEl, "touchend", onTouchEnd);
});
