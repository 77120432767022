import React, { MutableRefObject, useCallback } from "react";

/**
 * This hook facilitates adding keydown and keyup handlers to an element to make it behave like a button.
 * That includes triggering click events when space or enter are pressed.
 * The event handlers returned by this function must be passed to the element in question. Also, you'll
 * likely need to set tabIndex={0} to make sure the element can be focused.
 *
 * @param elementRef A reference to the element that ought to behave like a button.
 * @returns And object containing keyup and keydown handlers that should be added to the element.
 */
export function useButtonBehavior(
	elementRef: MutableRefObject<HTMLElement | undefined | null>,
): {
	onKeyDown(event: React.KeyboardEvent): void;
	onKeyUp(event: React.KeyboardEvent): void;
} {
	const onKeyDown = useCallback(
		(event: React.KeyboardEvent) => {
			if (
				!elementRef.current ||
				document.activeElement !== elementRef.current
			) {
				// Only handle events if this element is focused
				return;
			}

			if (event.key === " ") {
				event.preventDefault();
			} else if (event.key === "Enter") {
				elementRef.current.click();
			}
		},
		[elementRef],
	);

	const onKeyUp = useCallback(
		(event: React.KeyboardEvent) => {
			if (
				!elementRef.current ||
				document.activeElement !== elementRef.current
			) {
				// Only handle events if this element is focused
				return;
			}

			if (event.key === " ") {
				elementRef.current.click();
			}
		},
		[elementRef],
	);

	return { onKeyDown, onKeyUp };
}
