/* tslint:disable:no-reference */
/// <reference path="../types/require.d.ts" />
declare var MKSAP_BUNDLER: string;

// Let webpack know we're going to load images
require.context("@mksap/images", true);

// Not unit tested
export default function imagePath(path: string): string {
	try {
		return require("@mksap/images/" + path) as string;
	} catch (e) {
		// tslint:disable-next-line: no-console
		console.error(e);
		return "";
	}
}
