/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { QuestionResponse } from '../models/QuestionResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ResponsesService {
    /**
     * live_peer_comparison response
     * @returns any successful
     * @throws ApiError
     */
    public static getLivePeerComparison(): CancelablePromise<Record<string, {
        'A'?: number;
        'B'?: number;
        'C'?: number;
        'D'?: number;
        'E'?: number;
    }>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/responses/live_peer_comparison.json',
        });
    }
    /**
     * Get current answersheet
     * @returns QuestionResponse successful
     * @throws ApiError
     */
    public static getApiResponsesJson(): CancelablePromise<Array<QuestionResponse>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/responses.json',
        });
    }
    /**
     * create response
     * @param requestBody
     * @returns QuestionResponse successful
     * @throws ApiError
     */
    public static saveAnswer(
        requestBody?: {
            questionId: string;
            answer: string;
            quizId?: number;
            responseTimeMs?: number;
        },
    ): CancelablePromise<QuestionResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/responses.json',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
