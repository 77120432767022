// Not unit tested
import { noop } from "lodash-es";

if (
	typeof window.console === "undefined" ||
	typeof window.console.log === "undefined"
) {
	(window as any).console = {
		log: noop,
		warn: noop,
		error: noop,
	};
}
