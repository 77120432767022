import { useCallback, useState } from "react";

/*
 * A hook that encapsulates the show/hide logic for a dialog, expansion panel, etc.
 *
 * @example
 * export const DialogExample: React.FC = (_props) => {
 *   const [show, onShow, onHide] = useShow();
 *   return <>
 *       <Button onClick={onShow} raised>show</Button>
 *       <Dialog show={show} onHide={onHide}>dialog content</Dialog>
 *   </>;
 * };
 *
 */
export function useShow(initialShow: boolean = false) {
	const [showing, setShowing] = useState(initialShow);

	const show = useCallback(() => {
		setShowing(true);
	}, [setShowing]);

	const hide = useCallback(() => {
		setShowing(false);
	}, [setShowing]);

	const toggle = useCallback(() => {
		setShowing(!showing);
	}, [showing, setShowing]);

	return { showing, show, hide, toggle };
}
