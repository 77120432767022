import React, { forwardRef, useCallback, useRef } from "react";
import { IconName } from "@mksap/types/iconName";
/* eslint-disable no-restricted-imports */
import ReactBootstrapButton, {
	ButtonProps as ReactBootstrapButtonProps,
} from "react-bootstrap/Button";
import { useButtonBehavior } from "@mksap/components/hooks/useButtonBehavior";
import Icon from "./Icon";
import { ButtonSpinner } from "./ButtonSpinner";

import "./Button.scss";

export interface ButtonProps extends ReactBootstrapButtonProps {
	loading?: boolean;
	loadingMessage?: string;
	iconName?: IconName;
	iconPosition?: "start" | "end";
}

export const Button = forwardRef<
	HTMLButtonElement | HTMLAnchorElement,
	ButtonProps
>(
	(
		{
			loading,
			loadingMessage,
			iconName,
			iconPosition = "end",
			children,
			...rbsButtonProps
		},
		refToForward,
	) => {
		if (rbsButtonProps.target === "_blank" && !rbsButtonProps.rel) {
			rbsButtonProps.rel = "noopener noreferrer";
		}

		// Ensure button is disabled while loading.
		if (loading === true) {
			rbsButtonProps.disabled = true;
		}

		// Safari doesn't repaint elements with pointer-events: none, so we need
		// to use a component key to replace the element when disabled prop changes.
		rbsButtonProps.key = rbsButtonProps.disabled
			? "disabled-button"
			: "enabled-button";

		// Default to an <a> tag if an href is specifified
		// react-bootstrap should do this automatically doing it explicitly
		// here makes the code below easier to follow.
		if (typeof rbsButtonProps.href !== "undefined") {
			rbsButtonProps.as = rbsButtonProps.as ?? "a";
		}

		// Links styled as buttons should have role of "link" by default.
		if (rbsButtonProps.as === "a") {
			rbsButtonProps.role = rbsButtonProps.role ?? "link";
		}

		// If the Button element is being rendered as an <a> tag but has role button,
		// make it behave like a button.
		const myElRef = useRef<HTMLButtonElement | HTMLAnchorElement | null>(null);

		// We need a ref to the underlying button component, but we also want to forward any refs that are passed in.
		const setElRef = useCallback(
			(el: HTMLButtonElement | HTMLAnchorElement) => {
				myElRef.current = el;

				if (typeof refToForward === "function") {
					refToForward(el);
				} else if (refToForward) {
					refToForward.current = el;
				}
			},
			[refToForward],
		);
		const { onKeyDown, onKeyUp } = useButtonBehavior(myElRef);
		if (rbsButtonProps.as === "a" && rbsButtonProps.role === "button") {
			rbsButtonProps.onKeyDown = rbsButtonProps.onKeyDown || onKeyDown;
			rbsButtonProps.onKeyDown = rbsButtonProps.onKeyUp || onKeyUp;
			rbsButtonProps.tabIndex = rbsButtonProps.tabIndex || 0;
		}

		if (loading === true) {
			return (
				<ReactBootstrapButton {...rbsButtonProps}>
					<ButtonSpinner /> {loadingMessage || children}
				</ReactBootstrapButton>
			);
		}

		return (
			<ReactBootstrapButton ref={setElRef} {...rbsButtonProps}>
				{iconName && iconPosition === "start" && (
					<Icon name={iconName} className="svg-icon__first" />
				)}
				{children}
				{iconName && iconPosition !== "start" && (
					<Icon name={iconName} className="svg-icon__last" />
				)}
			</ReactBootstrapButton>
		);
	},
);
