import "./utils/setConsole";
// Polyfill to add document.scrollingElement
import "scrollingelement";

// Polyfill classList in order to make tippy.js work
// on IE11.
import "./utils/classListPolyfill";

if (!window.performance) {
	(window as any).performance = {
		mark(_markName: string) {
			// no-op
		},
		measure(_markName: string) {
			// no-op
		},
	};
}
