import { closest } from "@mksap/utils/jQueryShim";
import React, { forwardRef, useMemo, useRef } from "react";
// eslint-disable-next-line no-restricted-imports
import { Overlay as BaseOverlay, OverlayProps } from "react-bootstrap";

// Wrap the react-bootstrap Overlay to make sure it plays nice with focus-trap in modals
export const Overlay = forwardRef<HTMLElement, OverlayProps>(
	({ container, ...otherProps }, ref) => {
		const spanRef = useRef(null);
		const betterContainer = useMemo(() => {
			if (container) {
				// If a container prop is passed, just pass it down unchanged
				return container;
			} else {
				return () => {
					if (!spanRef.current) {
						return null;
					}

					// TODO: Consider handling this with a react context, instead of looking for the nearest modal in the DOM
					const closestModal = closest(spanRef.current, ".modal-content");
					if (closestModal) {
						// If the overlay is a modal, the container should be the modal
						return closestModal;
					} else {
						// Otherwise, use document.body as the default container, to be consistent with default react-bootstrap behavior
						return document.body;
					}
				};
			}
		}, [spanRef, container]);
		return (
			<>
				<BaseOverlay {...otherProps} ref={ref} container={betterContainer} />
				<span ref={spanRef} style={{ display: "none" }} />
			</>
		);
	},
);

Overlay.displayName = "Overlay";
Overlay.propTypes = BaseOverlay.propTypes;
