import classNames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Icon from "../ui/Icon";
import "./LandingPageCarousel.scss";

export type Slide = {
	image: string;
	text: React.ReactNode;
};

export const LandingPageCarousel: React.FC<{
	slides: Slide[];
}> = ({ slides }) => {
	const [currentSlide, setCurrentSlide] = useState(0);

	const nextSlide = useMemo(
		() => (currentSlide < slides.length - 1 ? currentSlide + 1 : 0),
		[currentSlide, slides.length],
	);

	const previousSlide = useMemo(
		() => (currentSlide > 0 ? currentSlide - 1 : slides.length - 1),
		[currentSlide, slides],
	);

	useEffect(() => {
		const interval = setInterval(() => setCurrentSlide(nextSlide), 5000);

		return () => clearInterval(interval);
	}, [nextSlide]);

	const imageContainerRef = useRef<HTMLDivElement>(null);

	const leftOffset = useMemo(
		() =>
			imageContainerRef.current
				? -imageContainerRef.current?.clientWidth * currentSlide
				: 0,
		[imageContainerRef, currentSlide],
	);

	return (
		<div className="new-carousel d-flex flex-column">
			{/* IMAGES */}
			<div
				className="new-carousel__image-container overflow-hidden mb-3 position-relative"
				ref={imageContainerRef}
			>
				<button
					className="new-carousel__nav-arrow new-carousel__nav-arrow--previous"
					onClick={() => setCurrentSlide(previousSlide)}
				>
					<Icon name={"chevron-left"} />
				</button>
				<div
					className="new-carousel__image-container-inner d-flex position-relative top-0 left-0"
					style={{ left: `${leftOffset}px` }}
				>
					{slides.map((slide) => (
						<img
							key={slide.image}
							src={slide.image}
							alt="lol"
							className="new-carousel__image-item"
						/>
					))}
				</div>
				<button
					className="new-carousel__nav-arrow new-carousel__nav-arrow--next"
					onClick={() => setCurrentSlide(nextSlide)}
				>
					<Icon name={"chevron-right"} />
				</button>
			</div>

			{/* TEXT */}
			<div className="new-carousel__text-container">
				{slides.map((slide, index) => (
					<div
						key={slide.image}
						className={classNames(
							"new-carousel__text-item text-white",
							index === currentSlide ? "d-block" : "d-none",
						)}
					>
						{slide.text}
					</div>
				))}
			</div>

			{/* NAV DOTS */}
			<div className="new-carousel__nav-container d-flex">
				{slides.map((slide, index) => (
					<button
						key={slide.image}
						className={classNames(
							"new-carousel__nav-item p-2",
							index === currentSlide && "current",
						)}
						onClick={() => setCurrentSlide(index)}
					>
						<div className="new-carousel__nav-item-inner bg-white" />
					</button>
				))}
			</div>
		</div>
	);
};
