import { delay } from "@mksap/utils/delay";

// The delay before the loading screen is shown, in milliseconds.
// Promises that resolve more in less time than this will not be delayed by withLoadingDelay.
export const initialLoadingScreenDelayMs = 250;

// The minimum amount of time the loading screen should be visible, in milliseconds.
// Promises that take longer than initialLoadingScreenDelayMs will be delayed by withLoadingDelay
// to ensure the loading screen is visible for at least minLoadingScreenTimeMs milliseconds.
const minLoadingScreenTimeMs = 500;

/**
 * Wraps a promise, potentially delaying its resolution to ensure a minimum loading screen time is met.
 *
 * This is intended to help avoid the situation where the loading screen flashes on the screen for a moment.
 */
export async function withLoadingDelay<T>(promise: Promise<T>): Promise<T> {
	const startTime = Date.now();
	const res = await promise;
	const timeTakenMs = Date.now() - startTime;

	const timeToDelay =
		minLoadingScreenTimeMs + initialLoadingScreenDelayMs - timeTakenMs;

	if (timeTakenMs > initialLoadingScreenDelayMs && timeToDelay > 0) {
		await delay(timeToDelay);
	}

	return res;
}
