import { IconName } from "@mksap/types/iconName";
import iconNameFromPath from "./iconNameForPath";

// Not unit tested
export function getSvgIconHref(iconName: string): string {
	return `#svg-${iconName}`;
}

const svgIconDefinitionsId = "svg-icon-definitions";

function parseSvg(svgSource): SVGElement | null {
	const aDiv = document.createElement("div");
	aDiv.innerHTML = svgSource;

	const svgElement = aDiv.firstElementChild;
	if (svgElement instanceof SVGElement) {
		return svgElement as any;
	} else {
		console.warn("expected an svg element, got: ", svgElement);
		return null;
	}
}

const svgNS = "http://www.w3.org/2000/svg";

export function addSvgDefinitionsToBody() {
	if (document.getElementById(svgIconDefinitionsId)) {
		return;
	}
	const svgIconContext = require.context("@mksap/svg-icons?raw", true);

	const svgElement = parseSvg(
		`<svg xmlns="http://www.w3.org/2000/svg" version="1.1" aria-hidden="true" style="position: absolute; width: 0; height: 0; overflow: hidden;"></svg>`,
	);

	if (!svgElement) {
		console.warn("Couldn't add SVG icons to body.");
		return;
	}

	const symbolDefsEl = document.createElementNS(svgNS, "defs");
	svgElement.appendChild(symbolDefsEl);

	for (const path of svgIconContext.keys()) {
		const svgSource = svgIconContext(path);
		const iconName = iconNameFromPath(path);

		if (!iconName) {
			continue;
		}

		const iconSvgEl = parseSvg(svgSource);
		if (!iconSvgEl) {
			console.warn(`could not load svg from ${path}`);
			continue;
		}
		const symbolEl = document.createElementNS(svgNS, "symbol");
		symbolEl.id = `svg-${iconName}`;
		const iconViewbox = iconSvgEl.getAttribute("viewBox");
		if (iconViewbox) {
			symbolEl.setAttribute("viewBox", iconViewbox);
		} else {
			console.warn("no viewBox for icon", symbolEl.outerHTML);
		}
		symbolEl.innerHTML = iconSvgEl.innerHTML;

		symbolDefsEl.appendChild(symbolEl);
	}

	svgElement.id = svgIconDefinitionsId;
	document.body.appendChild(svgElement);
}

export function getKnownIconNames(): IconName[] {
	const svgEl = document.getElementById(svgIconDefinitionsId);
	if (svgEl === null) {
		console.warn(`cannot find #${svgIconDefinitionsId}`);
		return [];
	}
	const res: IconName[] = [];

	for (const symbolEl of svgEl.querySelectorAll("symbol")) {
		if (symbolEl.id && symbolEl.id.startsWith("svg-")) {
			res.push(symbolEl.id.replace(/^svg-/, "") as IconName);
		}
	}

	return res;
}
