import { appOptions } from "../appOptions";
import { AjaxPromiseOptions, AjaxRequestOptions } from "types/ajax";

/**
 * Use options passed to makeAjaxPromise function to build request options.
 * @param {Object} options
 * @return {Object} request options
 */
export const getAjaxRequestOptions = (
	options: AjaxPromiseOptions,
): AjaxRequestOptions => {
	const requestOptions: AjaxRequestOptions & {
		headers: NonNullable<AjaxRequestOptions["headers"]>;
	} = {
		credentials: "same-origin",
		headers: {
			"Content-Type": options.contentType || "application/json",
		},
	};

	if (appOptions.native) {
		if (options.url.indexOf(appOptions.ajaxPrefix) === 0) {
			requestOptions.headers = {
				...requestOptions.headers,
				...getRequestAuthHeaders(),
			};
		}
	} else {
		requestOptions.headers = {
			...requestOptions.headers,
			...getRequestAuthHeaders(),
		};
	}

	// Add method if options.type exists. (Fetch defaults to "GET").
	if (options.type) {
		requestOptions.method = options.type;
	}

	// Add body if options.data exists.
	if (options.data) {
		requestOptions.body = options.data;
	}

	return requestOptions;
};

export function getRequestAuthHeaders(): Record<string, string> {
	const headers: Record<string, string> = {};
	if (appOptions.native) {
		// Add more headers based on app mode.
		if ((window as any).authToken) {
			headers["X-Auth-Token"] = (window as any).authToken;
		}

		if (appOptions.inCordova) {
			headers["X-App-Platform"] = "cordova";
		} else if (appOptions.inElectron) {
			headers["X-App-Platform"] = "electron";
		}

		if (appOptions.appVersion) {
			headers["X-App-Version"] = appOptions.appVersion;
		}
	} else {
		const tokenEl = document.querySelector('meta[name="csrf-token"]');
		if (tokenEl) {
			const content = tokenEl.getAttribute("content");
			if (content) {
				headers["X-CSRF-Token"] = content;
			}
		}
	}
	return headers;
}

export default getAjaxRequestOptions;
