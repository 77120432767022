import React, { ChangeEvent, FormEvent, useCallback, useState } from "react";
import {
	Alert,
	FormCheck,
	FormControl,
	FormLabel,
} from "../ui/MksapReactBootstrap";
import { Button } from "../ui/Button";
import LoginBackend from "backends/LoginBackend";
import { LoggedInUser } from "@mksap/types/loggedInUser";
import { useUniqueId } from "@mksap/components/hooks/useUniqueId";
import "./WebLoginForm.scss";

interface WebLoginFormProps {
	onLogIn(userData: LoggedInUser): void;
}
export const WebLoginForm: React.FC<WebLoginFormProps> = ({ onLogIn }) => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [rememberMe, setRememberMe] = useState(false);
	const [errorText, setErrorText] = useState<string | null>(null);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const handleUnChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setUsername(e.currentTarget.value);
		},
		[setUsername],
	);
	const handlePwChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setPassword(e.currentTarget.value);
		},
		[setPassword],
	);
	const handleRememberMeChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setRememberMe(e.currentTarget.checked);
		},
		[setRememberMe],
	);
	const handleSubmit = useCallback(
		async (event: FormEvent) => {
			event.preventDefault();
			if (submitting === true) {
				return;
			}
			setErrorText(null);
			setSubmitting(true);
			try {
				const userData = await LoginBackend.logIn(username, password);
				onLogIn(userData);
			} catch (err) {
				if (err.friendlyErrorMessage) {
					setErrorText(err.friendlyErrorMessage);
				} else {
					setErrorText(err.message);
				}

				setSubmitting(false);
			}
		},
		[onLogIn, submitting, setSubmitting, username, password, setErrorText],
	);

	const usernameId = useUniqueId("username");
	const passwordId = useUniqueId("password");

	return (
		<form className="h-100" onSubmit={handleSubmit}>
			<div className="d-flex flex-column h-100">
				<div>
					<div className="mb-3">
						<FormLabel htmlFor={usernameId}>Username</FormLabel>
						<FormControl
							autoComplete="username"
							className=""
							value={username}
							name="username"
							id={usernameId}
							onChange={handleUnChange}
						/>
						<div className="small form-text text-end">
							<a href="https://webapi.acponline.org/Account/ForgotUserName">
								Forgot your username?
							</a>
						</div>
					</div>
					<div className="mb-3">
						<FormLabel htmlFor={passwordId}>Password</FormLabel>

						<FormControl
							className=""
							name="password"
							id={passwordId}
							autoComplete="current-password"
							type="password"
							value={password}
							onChange={handlePwChange}
						/>
						<div className="small form-text text-end">
							<a href="https://webapi.acponline.org/Account/ForgotPassword">
								Forgot your password?
							</a>
						</div>
					</div>
					{errorText && (
						<Alert className="mb-3" variant="danger">
							{errorText}
						</Alert>
					)}
					<FormCheck
						label="Remember me"
						onChange={handleRememberMeChange}
						checked={rememberMe}
					/>
					<div className="d-grid gap-2 py-3 col-10 mx-auto">
						<Button type="submit">Login</Button>
					</div>
				</div>
				<div className="text-center mt-auto pb-3">
					<p>New to MKSAP?</p>
					<Button
						className="btn-secondary"
						href="https://www.acponline.org/featured-products/acp-mksap"
					>
						Order ACP MKSAP Now
					</Button>
				</div>
			</div>
		</form>
	);
};
